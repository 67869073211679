/* eslint-disable no-param-reassign */
import {
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';

import {
  Box,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import {
  modules,
  routes,
} from '@config';
import { useTenant } from '@lib/hooks';
import MainSidebar from '../MainSidebar';
import MainNavbar from '../MainNavbar';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

export default function MainLayout(props) {
  const { children } = props;

  const router = useRouter();
  const { status } = useSession();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { tenant } = useTenant();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sections = useMemo(() => getSidebarSections(t, i18n, router, tenant, theme), [
    t,
    i18n,
    router,
    tenant,
    theme,
  ]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (status !== 'authenticated') {
      router.replace(`${routes.auth.login}?return_path=${router.asPath}`);
    }
  }, [router, router.isReady, status]);

  if (status !== 'authenticated') {
    return null;
  }

  return (
    <>
      <MainLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </MainLayoutRoot>
      <MainNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <MainSidebar
        onClose={() => setIsSidebarOpen(false)}
        open={isSidebarOpen}
        sections={sections}
      />
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: null,
};

function getSidebarSections(t, i18n, router, tenant, theme) {
  // Get all the menu items from the various modules and apply
  // customizations made in the backend from the tenant.

  const items = [
    ...modules.flatMap((module) => module.menu?.getSections(t, i18n, router, tenant) ?? []),
  ];

  items.forEach((section) => {
    applyCustomizationToNavItems(section.items, i18n, tenant, theme);
  });

  return items;
}

function applyCustomizationToNavItems(items, i18n, tenant, theme) {
  items.forEach((item) => {
    if (item.children && Array.isArray(item.children)) {
      applyCustomizationToNavItems(item.children, i18n, tenant, theme);
    }

    const moduleConfig = tenant.attributes.modules.find((m) => (Array.isArray(item.type)
      // eslint-disable-next-line no-underscore-dangle
      ? item.type.includes(m.__typename)
      // eslint-disable-next-line no-underscore-dangle
      : m.__typename === item.type));

    if (!moduleConfig) {
      // Skip missing configs
      return;
    }

    if (item.attribute) {
      const navItemCustomization = moduleConfig[item.attribute];

      if (!navItemCustomization) {
        // Nav item was not localized.
        return;
      }

      // Apply label customization
      item.title = navItemCustomization[`label_${i18n.language}`] || item.title;

      if (navItemCustomization.predefinedIconName) {
        // @TODO
      }

      if (navItemCustomization.customIcon?.data) {
        const { url } = navItemCustomization.customIcon.data.attributes;

        item.icon = (
          <span
            className="svgIconMask"
            style={{
              width: 20,
              height: 20,
              maskImage: `url("${url}")`,
            }}
          />
        );
      }
    }
  });
}
